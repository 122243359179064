html {
  font-size: 62.5%; // 1rem = 10px
}

body {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  overflow-x: hidden;
  max-width: 100vw;
  font-family: $font-family-1;
  background-color: $color-1;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

input:focus,
button:focus,
textarea:focus {
  outline: none;
}

textarea,
input,
input[type="text"],
input[type="password"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 0;
}

a {
  transition: .25s color;
  will-change: color;

  &:visited,
  &:link {
    color: $color-1;
  }

  &:hover,
  &:active {
    color: $color-2;
  }
}

.u-c-1 a {
  &:visited,
  &:link {
    color: #fff;
  }
}

img {
  max-width: 100%;
}

.icon {
  width: 10rem;

  @include respondMf(xlg) {
    width: 18rem;
  }
}

.z-index-1 { z-index: 1; }

.box--limited,
.text--limited {
  max-width: 37rem;
  margin-left: auto;
  margin-right: auto;
}

.price-list {
  display: inline-block;
  padding: 0 0 2rem;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

  .text {
    text-align: left;
    padding: 0 2rem;
  }
}
