
/* 1) Border Box Everywhere */
html {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

/* 2) Viewport on Windows Phone */
@at-root {
  @-ms-viewport { width: device-width; }
}
body {
  -ms-overflow-style: scrollbar;
}

/* 3) Removing 300ms Delay */
a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

/* 4) Reset for min-width: min-content */
fieldset {
  /* Chrome and Firefox setting here
      min-width: min-content */
  min-width: 0;
}

