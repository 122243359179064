
.logo {
  position: relative;
  width: 12rem;

  @include respondMf(xlg) {
    width: 20rem;
  }
}

.logo__img {
  width: 100%;
}
