
.footer {
  padding: 4rem 0;
  overflow: hidden;
  background-image: url('#{$path-image}/layout/footer.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.footer__logo {
  max-width: 22rem;
}

.footer__contacts {
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-text-horizontal__text {
    font-size: 1.2rem;

    @include respondMf(lg) {
      font-size: 1.4rem;
    }
  }
}

.footer__links {
  margin: 0;
  padding: .5rem 0;
  list-style: none;
  border-top: .1rem solid $color-4;
  border-bottom: .1rem solid $color-4;

  @include respondMf(sm) {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: .5rem 1rem;
  }

  a {
    display: inline-block;
    padding: .5rem 0;
    text-transform: uppercase;
    text-decoration: none;
    font-family: $font-family-2;
    font-weight: 700;
    font-size: 1.4rem;

    @include respondMf(sm) {
      padding: .5rem;
    }

    @include respondMf(lg) {
      padding: 1rem 1.5rem;
      font-size: 1.6rem;
    }
  }

  a:hover,
  a:active {
    color: $color-4;
  }
}

.footer__copyright {
  text-transform: uppercase;

  p {
    @include respondMf(lg) {
      font-size: 1.4rem;
    }
  }
}
