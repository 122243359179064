
.menu-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: .5rem 0;
  background-color: $color-1;
  border-top: .1rem solid $color-2;

  @include respondMf(xlg) {
    padding: 0;
  }
}

.menu-container--is-scrolled {
  position: fixed;
}

.menu-container--is-animated {
  transition: .25s top;
  will-change: top;
  box-shadow: 0 2.6rem 4rem 0 rgba(0, 0, 0, 0.15);
}

.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu .logo {
  margin: 0 1.5rem 0 0;
}

.menu .logo,
.menu .menu-user {
  position: relative;
  z-index: 1010;
}

.menu-links {
  display: flex;

  @include respondMf(xlg) {
    align-items: center;
    justify-content: space-around;
  }
}

.menu a {
  text-decoration: none;

  &:link,
  &:visited {
    color: #fff;
  }

  &:hover,
  &:active,
  &.nav__link--active {
    color: $color-3;
  }
}

