
.logos {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logos.tns-horizontal.tns-subpixel > .logos__item {
  display: flex;
  align-items: center;
  justify-content: center;

  @include respondMf(lg) {
    padding: 0 5rem;
  }
}

.logos__item img {
  filter: grayscale(100%);
  transition: .25s filter;
  will-change: filter;
}

.logos__item:hover img {
  filter: grayscale(0);
}
