
a.btn {
  display: inline-block;
  padding: 1.2em 3.3em;
  border-radius: 5rem;
  border: .1rem solid #fff;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: 1px 1px 0 rgba(0,0,0,.75);
  font-size: 1.4rem;
  font-weight: 900;
  letter-spacing: .05em;
  line-height: 1;
  user-select: none;
  transition: .25s background-color, .25s color;

  @include respondMf(lg) {
    font-size: 1.8rem;
  }
}

a.btn--inverse {
  text-shadow: 1px 1px 0 rgba(0,0,0,.75);
  font-size: 1.2rem;
  font-weight: 900;
  border-color: $color-1;
  text-shadow: none;

  @include respondMf(lg) {
    font-size: 1.8rem;
  }
}

a.btn--dark {
  padding: 1.2em 1.8em 1em;
  text-shadow: none;
  font-family: $font-family-2;
  font-size: 1.2rem;
  color: #000;
  border-color: #000;

  @include respondMf(lg) {
    font-size: 1.6rem;
  }
}

a.btn--inverse-c-1 {
  border-color: $color-4;

  &:link,
  &:visited {
    color: $color-4;
  }
}

a.btn:hover,
a.btn:active {
  color: #fff;
  background-color: $color-4;
  border-color: $color-4;
}


