
.section {
  padding: 4rem 0;

  @include respondMf(md) {
    padding: 8rem 0;
  }
}

.section--skew {
  position: relative;
  background-color: #fff;
  z-index: 1;
}

.section--skew::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform-origin: top left;
  transform: skewY(-10deg);
  z-index: -10;

  @include respondMf(md) {
    transform: skewY(-5deg);
  }
}

.section--bg {
  position: relative;

  .container {
    position: relative;
    z-index: 2;
  }
}

.section--bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(black, .7);
  z-index: 1;
}

.section--bg__img {
  position: absolute;
  top: -13rem;
  left: 0;
  width: 100%;
  height: 140%;
  object-fit: cover;
  object-position: center center;
  font-family: "object-fit:cover;object-position:center center;";

  @include respondMf(xlg) {
    top: -18rem;
  }

  .section--video & {
    height: 150%;
  }
}

.section--01 {

  .row {
    @include respondMf(sm) {
      flex-direction: row-reverse;
    }
  }
}

.section--02 {
  padding-bottom: 8rem;

  @include respondMf(sm) {
    padding-bottom: 14rem;
  }

  @include respondMf(lg) {
    padding-bottom: 22rem;
  }

  @include respondMf(xlg) {
    padding-bottom: 28rem;
  }
}

.section--03 {

  h2 {
    position: relative;
    z-index: 5;

    @include respondMf(lg) {
      display: inline-block;
    }

    @include respondMf(xlg) {
      line-height: 1;
    }
  }

  h3 {

    @include respondMf(lg) {
      font-size: 3rem;
    }
  }

  .col {
    &:nth-of-type(2) {

      //@include respondMf(sm) {
        z-index: 3;
      //}
    }

    &:nth-of-type(3) {

      //@include respondMf(sm) {
        z-index: 2;
      //}
    }

    &:last-of-type {

      //@include respondMf(sm) {
        z-index: 1;
      //}
    }
  }
}

.section--video {
  min-height: 40rem;
  padding: 8rem 0;

  @include respondMf(sm) {
    padding: 8rem 0 10rem;
  }

  @include respondMf(md) {
    padding: 14rem 0 12rem;
  }

  @include respondMf(lg) {
    padding: 16rem 0;
  }

  @include respondMf(xlg) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
  }

  video {
    display: none;
    width: 100%;
    object-fit: contain;

    @include respondMf(xlg) {
      width: auto;
      height: 100%;
    }
  }
}

.section--map {
  padding: 0;
}

.section--map__map {
  height: 40rem;

  @include respondMf(lg) {
    height: 58rem;
  }
}

.section--logos {
  background-color: #fff;

  .row {
    @include respondMf(sm) {
      justify-content: center;
    }
  }

  img {
    width: 70%;

    @include respondMf(lg) {
      width: auto;
    }
  }
}

.section--c-1 {
  background-color: #fff;
}