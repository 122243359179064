
.picture-frame {
  position: relative;
  margin: 0 3rem;
  height: 25rem;

  @include respondMf(iPhone) {
    height: 35rem;
  }

  @include respondMf(sm) {
    margin: 0;
    height: 25rem;
  }

  @include respondMf(md) {
    height: 28rem;
  }

  @include respondMf(lg) {
    height: 40rem;
  }

  @include respondMf(xlg) {
    height: 50rem;
  }
}

.picture-frame::before {
  content: '';
  position: absolute;
  top: -7%;
  left: -25%;
  width: 55%;
  height: 20%;
  border-bottom: .2rem solid $color-4;
  transform: rotate(-45deg);
  background-color: #fff;
  z-index: 1;

  @include respondMf(md) {
    top: -8%;
    left: -15%;
    width: 35%;
    height: 20%;
  }

  @include respondMf(lg) {
    top: -8%;
    left: -26%;
    width: 60%;
    height: 20%;
  }

  @include respondMf(xlg) {
    top: -10%;
    left: -20%;
    width: 40%;
    height: 20%;
    border-bottom-width: .4rem;
  }
}

.picture-frame__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
  font-family: "object-fit:cover;object-position:center top;";

  @include respondMf(xlg) {
    object-position: center center;
    font-family: "object-fit:cover;object-position:center center;";
  }
}
