
.gallery {
  display: flex;
  flex-wrap: wrap;
}

.gallery__item {
  position: relative;
  flex: 0 0 50%;
  width: 50%;
  max-width: 50%;
  margin: 0;

  @include respondMf(sm) {
    flex: 0 0 20%;
    width: 20%;
    max-width: 20%;
  }

  &::before {
    content: '';
    height: 0;
    width: 100%;
    display: block;
    padding-bottom: 100%;
  }

  &--odd {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;

    @include respondMf(sm) {
      flex: 0 0 20%;
      width: 20%;
      max-width: 20%;
    }

    &::before {
      padding-bottom: 50%;

      @include respondMf(sm) {
        padding-bottom: 100%;
      }
    }
  }
}

.gallery__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(black, .5);
    transition: .5s background-color;
    will-change: background-color;
  }

  .gallery__item:hover &::after {
    background-color: rgba(black, .1);
  }
}

.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  font-family: 'object-fit:cover;object-position: center center;';
}
