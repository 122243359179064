
.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
  text-align: center;
  background-color: $color-1;

  @include respondMf(md) {
    padding: 6rem 0 4rem;
  }
}

.box--c-2 {
  background-color: $color-4;
}

.box__title {
  margin: 0 0 2rem;
  text-transform: none;
  font-size: 3rem;
  font-weight: 700;
  color: #fff;

  @include respondMf(md) {
    font-size: 6rem;
  }
}

.box__subtitle {
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  color: #fff;

  @include respondMf(md) {
    font-size: 2.4rem;
  }
}
