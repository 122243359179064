
.topbar {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  padding: .5rem 0;
  background-color: $color-2;

  .socials {
    display: none;

    @include respondMf(sm) {
      display: flex;
      justify-content: flex-end;
      width: 10rem;
    }
  }

  .socials__item {

    @include respondMf(sm) {
      flex: 0 0 2.5rem;
    }
  }

  .socials__item:last-of-type {
    padding-right: 0;
  }
}

.topbar__inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include respondMf(sm) {
    justify-content: space-between;
  }
}

.topbar__contacts {
  display: flex;
}
