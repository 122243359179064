
h1,
h2 {
  margin: 0 0 2rem;
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: 900;
  line-height: .9;
  color: $color-1;

  @include respondMf(md) {
    margin: 0 0 3rem;
  }

  @include respondMf(lg) {
    font-size: 6rem;
    line-height: .6;
  }

  small {
    font-size: 2rem;
    font-weight: 400;

    @include respondMf(lg) {
      font-size: 3rem;
    }
  }
}

h3 {
  margin: 0 0 1rem;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 700;
  color: $color-4;

  @include respondMf(lg) {
    font-size: 2.5rem;
  }
}

h1.title-decoration,
h2.title-decoration {
  position: relative;

  &::after,
  &::before {
    content: '';
    position: absolute;
    display: block;
    background-color: $color-4;
    transform: rotate(-45deg);
    z-index: -1;
  }
  &::after {
    top: .5rem;
    left: -2rem;
    width: 7rem;
    height: .3rem;

    @include respondMf(xlg) {
      top: 2.5rem;
      left: -3.5rem;
      width: 16rem;
      height: .4rem;
    }
  }
  &::before {
    top: 1rem;
    left: -2.5rem;
    width: 10rem;
    height: .4rem;

    @include respondMf(xlg) {
      top: 4rem;
      left: -6.5rem;
      width: 25rem;
      height: .6rem;
    }
  }
}

.u-c-1 h1,
.u-c-1 h2 {
  color: #fff;
}

p {
  margin: 0 0 2rem;
  font-family: $font-family-2;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.8;

  @include respondMf(md) {
    margin: 0 0 3rem;
    font-size: 1.8rem;
  }

  strong {
    font-weight: 700;
  }
}

.u-c-1 p {
  color: #fff;
}

.text ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 1.4rem;
  line-height: 1.8;
  color: $color-5;

  @include respondMf(md) {
    font-size: 1.8rem;
  }

  li {
    position: relative;
    margin: 0 0 1rem;
    padding: 0 0 0 2rem;
  }

  li::before {
    content: '';
    position: absolute;
    top: .6rem;
    left: 0;
    width: 1.4rem;
    height: 1.4rem;
    background-image: url('#{$path-image}/icons/i-ball.svg');
    background-repeat: no-repeat;
    background-position: center center;

    @include IESelector() {
      background-image: url('#{$path-image}/icons/i-ball.png');
    }

    @include respondMf(md) {
      top: .9rem;
    }
  }
}

.u-c-1.text ul { color: #fff; }
