
.pictures {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;

  @include respondMf(md) {
    height: 66rem;
  }
}

.pictures__pic {
  position: relative;
  flex: 0 0 40%;
  width: 40%;
  max-width: 40%;
  height: 100%;
  margin: .5rem;

  @include respondMf(md) {
    max-width: 24rem;
  }

  &:last-of-type {
    margin-top: 2rem;

    @include respondMf(lg) {
      margin-top: 4rem;
    }
  }
}

.pictures__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  font-family: "object-fit:cover;object-position:center center;";
}
