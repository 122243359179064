.instagram_gallery {
  display: flex;
  flex-wrap: wrap;
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
}

.instagram_gallery a {
  display: block;
  padding: .5rem;
  flex: 0 0 33.3333%;
}