
.video-play-button {
  position: relative;
  box-sizing: content-box;
  display: inline-block;
  width: 32px;
  height: 44px;
  /* background: #fa183d; */
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}

.video-play-button--stop {
  position: absolute;
  left: 1.5rem;
  bottom: 3rem;

  display: none;

  @include respondMf(lg) {
    bottom: 5rem;
  }

  @include respondMf(xlg) {
    position: relative;
    bottom: 3rem;
  }
}

.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: $color-3;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: $color-3;
  border-radius: 50%;
  transition: all 200ms;
}

.video-play-button:hover:after {
  background-color: darken($color-3, 10%);
}

.video-play-button img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video-play-button span {
  display: block;
  position: relative;
  top: 10px;
  left: 7px;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 16px solid #fff;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
}

.video-play-button strong {
  position: relative;
  top: 11px;
  left: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2rem;
  z-index: 10;
  width: 1.8rem;

  @include respondMf(xlg) {
    top: 0;
  }
}

.video-play-button strong::before,
.video-play-button strong::after {
  content: '';
  display: block;
  flex: 0 0 40%;
  height: 100%;
  background-color: #fff;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}