
a.icon-text-horizontal {
  display: flex;
  align-items: center;
  text-decoration: none;

  &:link,
  &:visited {
    color: #fff;
  }

  &:hover,
  &:active {
    color: $color-4;
  }
}

.icon-text-horizontal__picture {
  flex: 0 0 2.4rem;
  width: 2.4rem;
  max-width: 2.4rem;
  text-align: center;

  @include respondMf(md) {
    flex: 0 0 3rem;
    width: 3rem;
    max-width: 3rem;
  }

  &--phone img {
    position: relative;
    top: .1rem;
    width: 1.2rem;

    @include respondMf(md) {
      //width: 1.8rem;
    }
  }

  &--envelope img {
    position: relative;
    top: .2rem;
    width: 1.3rem;

    @include respondMf(md) {
      //width: 2.2rem;
    }
  }
}

.icon-text-horizontal__text {
  font-size: 1.3rem;
  font-weight: 400;
}


