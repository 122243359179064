
.socials {
  display: flex;
  align-items: center;
  justify-content: center;
}

.socials__item {
  display: block;
  flex: 0 0 4rem;
  width: 4rem;
  max-width: 4rem;
  box-sizing: content-box;
  padding: 0 .75rem;
  opacity: 1;
  transition: .25s opacity;
  will-change: opacity;

  &:hover {
    opacity: .5;
  }
}

.socials__img {
  width: 100%;
}
