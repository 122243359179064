
// paths
$path-image: '../img';

// mf - mobile first
$grid-breakpoints-mf: (
        iPhone: 375px,
        xs: 25em, // 400px
        sm: 36em, // 576px
        md: 48em, // 768px
        lg: 62em, // 992px
        xlg: 75em, // 1200px
        sm-desktop: 90em, // 1440px
        desktop: 120em // 1920px
);

// fonts
$font-family-1: 'Lato', sans-serif;
$font-family-2: 'Raleway', sans-serif;

// colors
$color-1: #001c31;
$color-2: #053051;
$color-3: #91c01d;
$color-4: #fc7306;
$color-5: #777777;

$color-6: #4496fb;
$color-7: #d9d9d9;
$color-8: #42ac71;
$color-9: #e86a6a;

// selects - slim select,choices
$select-color: $color-2;
$select-text-color: $color-3;
$select-size: 1.4rem;

// popups & modals - micromodal
$popup-color: $color-2;
$popup-var-2-color: $color-8;
$popup-var-3-color: $color-9;
