
.header {
  position: relative;
  padding: 4rem 0 8rem;

  @include respondMf(sm) {
    padding: 8rem 0 12rem;
  }

  @include respondMf(lg) {
    padding: 20rem 0 24rem;
  }

  @include respondMf(xlg) {
    padding: 28rem 0 28rem;
  }

  .container {
    position: relative;
    z-index: 2;
  }
}

.header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(black, .7);
  z-index: 1;
}

.header__bg {
  position: absolute;
  top: -2rem;
  left: 0;
  width: 100%;
  height: 120%;
  object-fit: cover;
  object-position: center center;
  font-family: "object-fit:cover;object-position:center center;";

  @include respondMf(xlg) {
    top: -4rem;
  }
}


